import i18n from "../i18n";
import { useTranslation } from "react-i18next";
import truck from "../assets/truck.svg";
import { useLocation } from "react-router-dom";

const NotFound = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const hash = location.pathname.split("/")[1];
  const isWalkie = hash && hash.length > 1 && hash.length < 15 ? true : false;
  return (
    <section className="md:my-6 md:bg-white rounded-2xl md:shadow-app  max-w-screen-md mx-auto flex flex-col items-center  gap-10 md:p-10 p-6">
      <img alt="" src={truck} className="w-[14.813rem]  md:order-last " />
      <h1 className="text-2xl leading-8 font-semibold ">
        {isWalkie ? t("freeze-walkie-title") : t("freeze-card-title")}
      </h1>
      {isWalkie ? (
        <div>
          <p>{t("freeze-walkie-description-1")}</p>
          <br /> <p>{t("freeze-walkie-description-2")}</p>
          <br />
          <p>{t("freeze-walkie-description-3")}</p>
          <br />
          <p>
            {t("freeze-walkie-description-4")}
            <a
              href="https://moneywalkie.com/"
              className="items-center justify-center gap-y-1 w-[3.75rem] underline text-blue-800"
            >
              {t("freeze-walkie-description-5")}
            </a>
            {t("freeze-walkie-description-6")}
          </p>
          <p></p>
          <p></p>
        </div>
      ) : (
        <div>
          <p>{t("freeze-card-description-1")}</p>
          <br />
          <p>{t("freeze-card-description-2")}</p>
        </div>
      )}
    </section>
  );
};
export default NotFound;
